import React, { Component } from 'react';
import mondaySdk from "monday-sdk-js";
import { Box, Flex, Link } from "monday-ui-react-core";
import { Checkbox, Button } from "monday-ui-react-core";
import { ExternalPage } from "monday-ui-react-core/icons";
import { downloadCreatedDocument } from './fileDownloader';
import axios from 'axios';
import DOMPurify from 'dompurify';

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class BoardPrint extends Component {
    static displayName = BoardPrint.name;

    constructor(props) {
        super(props);

        var blueprint = {
            filename: "DocExport_Board_Print.docx",
            tableName: "BoardTable"
        };
        var dataSource = "Board";
        if (props.isItemPrint) {
            blueprint = {
                filename: "DocExport_Item_Print.docx",
                tableName: "SubitemsTable"
            };
            dataSource = "SingleItem";
        }

        const blueprintTableSelectedColumns = window.towerAppInfos?.tableColumnLists?.[blueprint.tableName] || [];  // TODO: Check if columns exist

        this.state = {
            loading: true,
            executing: false,
            dataSource: dataSource,
            selectedTemplate: null,
            documentContent: undefined,
            documentError: undefined,
            selectedBlueprint: blueprint,
            blueprintTableSelectedColumns: blueprintTableSelectedColumns,
        };

        this.texts = {
            pleaseDontCloseText: "Please don't close this tab while generating the document.",
            selectColumnsText: "Specify which columns you want to include in the table:"
        }
        if (props.isItemPrint)
            this.texts.selectColumnsText = "Specify which subitems columns you want to include in the subitems table:"

        this.userLanguage = 'en';
        this.mondayContext = window.mondayContext;
        if (this.mondayContext)
            this.userLanguage = this.mondayContext.user.currentLanguage;
        this.handleClickGenerate = this.handleClickGenerate.bind(this);
        this.handleTableColumnCheckboxChange = this.handleTableColumnCheckboxChange.bind(this);
        this.closeAssistant = this.closeAssistant.bind(this);
        this.downloadCreatedDocument = this.downloadCreatedDocument.bind(this);
        this.previewCreatedDocument = this.previewCreatedDocument.bind(this);
    }

    componentDidMount() {
        this.setupBlueprintTableSelectedColumns();
        this.setState({ loading: false });
    }

    setupBlueprintTableSelectedColumns() {
        if (this.state.blueprintTableSelectedColumns.length > 0)
            return;
        var blueprintTableSelectedColumns = [];
        var columns = this.getColumnsForTable();
        for (let i = 0; i < columns.length && i < 5; i++) {
            var id = columns[i].id;
            if (!blueprintTableSelectedColumns.includes(id))
                blueprintTableSelectedColumns.push(id);
        }
        this.setState({ blueprintTableSelectedColumns: blueprintTableSelectedColumns });
    }

    componentDidUpdate(prevProps) {        
        if (this.props.isItemPrint) {
            if (this.props.subItemsColumns !== prevProps.subItemsColumns) {
                //console.log('subItemsColumns prop has changed');
                if (this.state.blueprintTableSelectedColumns.length === 0)
                    this.setupBlueprintTableSelectedColumns();
            }
        }
    }
    async downloadCreatedDocument() {
        await downloadCreatedDocument(this.state.documentContent);
    }

    async previewCreatedDocument() {
        var urlPath = 'https://' + window.location.host +
            '/document/Download?Id=' + this.state.documentContent.id + '&ForViewer=true&SessionToken=' + window.mondaySessionToken
        monday.execute("openLinkInTab", { url: urlPath });
    }

    closeAssistant() {
        this.setState({
            documentContent: undefined,
            documentError: undefined,
            selectedTemplate: null
        });
    }

    handleTableColumnCheckboxChange(column) {
        var id = column.id;
        var newCheckedColumns;
        if (this.state.blueprintTableSelectedColumns.includes(id))
            newCheckedColumns = this.state.blueprintTableSelectedColumns.filter(c => c !== id);
        else
            newCheckedColumns = [...this.state.blueprintTableSelectedColumns, id];
        this.setState({ blueprintTableSelectedColumns: newCheckedColumns });
    }

    async handleClickGenerate() {
        this.setState({ executing: true });
        monday.execute("valueCreatedForUser");

        monday.execute("notice", {
            message: this.texts.pleaseDontCloseText,
            type: "info", // or "error" (red), or "info" (blue)
            timeout: 3000,
        });

        try {
            const data = {
                accountId: this.mondayContext.account?.id,
                boardId: this.mondayContext.boardId,
                sessionToken: window.mondaySessionToken,
                itemTerminology: DOMPurify.sanitize(this.props.columns.find(c => c.type === 'name')?.item_terminology),
                blueprintFilename: DOMPurify.sanitize(this.state.selectedBlueprint.filename),
            }
            data.Tables = [{
                Name: this.state.selectedBlueprint.tableName,
                Columns: this.getColumnsForTable().filter(c => this.isBlueprintTableColumnChecked(c)).map(c => ({
                    Id: c.id,
                    Title: c.title,
                    Type: c.type,
                    MirrorType: c.mirrorType,
                    MirrorSettings: c.mirrorSettings,
                    width: c.width,
                    settings_str: c.settings_str
                }))
            }];

            const response = await axios.post('template/createFromBlueprint', data);
            var responseData = response.data;
            if (responseData.result) {
                this.setState({
                    selectedTemplate: responseData.template,
                    //executing: false
                }, () => {
                    const data = this.getDataForPOST();
                    this.generateDocument(data);
                });
                //this.internalSelectDataSource(this.state.selectedBlueprint.dataSource, 1);
            }
        } catch (error) {
            // Error handling
            console.error("Error creating template from blueprint:", error);
            this.setState({
                // Example of setting an error state or message
                error: "Failed to create template. Please try again.",
                templateCreating: false
            });
        }
    }

    generateUniqueID() {
        return 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now();
    }

    getDataForPOST() {
        const data = {
            requestId: this.generateUniqueID(),
            accountId: this.mondayContext.account?.id,
            boardId: this.mondayContext.boardId,
            userId: this.mondayContext.user.id,
            workspaceId: this.mondayContext.workspaceId,
            userCountryCode: this.mondayContext.user.countryCode,
            userLanguage: this.mondayContext.user.currentLanguage,
            userTimeZoneOffset: this.mondayContext.user.timeZoneOffset,
            userTimeFormat: this.mondayContext.user.timeFormat,
            templateId: DOMPurify.sanitize(this.state.selectedTemplate?.id),
            sessionToken: window.mondaySessionToken,
            generateThumbnail: true,
        };
        if (this.state.dataSource === "SingleItem")
            data.itemId = this.mondayContext.itemId;
        if (this.state.documentContent)
            data.predecessorVersionId = this.state.documentContent.id;
        if (this.state.useFilter && this.state.mondayFilterText) {
            data.itemFilter = this.state.mondayFilterText;
        }
        return data;
    }

    async generateDocument(data) {
        var boardData = await this.loadBoardData();
        if (boardData)
            data.boardData = boardData.data;

        this.startWaitForDataRequest(data.requestId);

        try {
            const response = await axios.post('document/generateDocument', data);
            //console.log(response.data);
            if (response.data.result) {
                this.setState({ executing: false, documentContent: response.data.document, documentError: undefined });
            } else {
                // TODO
                var errorMessage = 'Error generating document: ' + response.data.error;
                console.log(errorMessage);
                if (response.data.documentLimitReached) {
                    alert(errorMessage);
                    this.setState({ executing: false });
                }
                else {
                    this.setState({ executing: false, documentError: response.data, documentContent: undefined });
                }
            }
        } catch (error) {
            // TODO: Send error to server
            console.error('Error:', error);
            alert(error);
            this.setState({ executing: false });
        }
    }

    startWaitForDataRequest(requestId, queryResult = undefined) {
        var waitForDataRequestData = {
            boardId: this.mondayContext.boardId,
            sessionToken: window.mondaySessionToken,
            requestId: requestId,
            queryResult: JSON.stringify(queryResult)
        };
        axios.post('document/WaitForDataRequest', waitForDataRequestData)
            .then(response => {
                console.log(response);
                if (response.data.action === 'Query') {
                    monday.api(response.data.query, { apiVersion: '2023-10' })
                        .then(queryResult => {
                            console.log(queryResult);
                            this.startWaitForDataRequest(requestId, queryResult.data);
                        }).catch(error => {
                            console.error('API call failed:', error);
                            //var errorMessage = error?.data?.errors?.[0]?.message ?? 'Default error message';
                            var errorMessage = 'Default error message';
                            if (error)
                                errorMessage = JSON.stringify(error, null, 2); // Stringify the entire error object
                            console.error('Error message:', errorMessage);
                            var errorInDataRequestData = {
                                boardId: this.mondayContext.boardId,
                                sessionToken: window.mondaySessionToken,
                                requestId: requestId,
                                errorMessage: errorMessage,
                            };
                            axios.post('document/ErrorInDataRequest', errorInDataRequestData)
                                .then(response => {
                                    //console.log('Error reported to server:', response.data);
                                    this.startWaitForDataRequest(requestId, queryResult.data);
                                })
                                .catch(serverError => {
                                    // Handle any errors in reporting the error to the server
                                    console.error('Failed to report error to server:', serverError);
                                });
                        });
                }
            })
            .catch(error => {
                console.error('Error with WaitForDataRequest:', error);
            });
    }

    getItemsQueryPart(withAssets) {
        var assetsPart = withAssets ? `assets { id public_url name }` : '';
        return `
      items {
        id
        name
        group {
            id
        }
        ${assetsPart}
        column_values {
          id
          type
          text
          value
          ... on MirrorValue {
            display_value
          }
          ... on BoardRelationValue {
            display_value
          }
          ... on DependencyValue {
            display_value
          }
        }
      }
`;
    }

    async loadBoardData() {
        if (!this.mondayContext.connected) {
            // TODO: What now?
            return;
        }

        var itemsLimit = 100;
        var itemsQueryPart = this.getItemsQueryPart(true);

        var itemFilterText = "";
        if (this.state.useFilter && this.state.mondayFilterText)
            itemFilterText = `query_params: {rules: ${this.state.mondayFilterText}}`;

        // Complexity with limit:500 1529580 (without assets: 24580)
        // Complexity with limit:100  309986 (without assets:  8986)
        var boardData = await monday.api(`
{
  complexity {
    query
    after
  }
  boards(ids: [${this.mondayContext.boardId}]) {
    id
    name
    item_terminology
    board_folder_id
    type
    description
    columns {
      title
      id
      type
      width
      settings_str
    }
    groups {
      id
      color
      title
    }
    owners {
      id
      name
      email
      phone
      photo_original
      photo_small
    }
    subscribers {
      id
      name
      email
      phone
      photo_original
      photo_small
    }
    items_page(
      limit: ${itemsLimit},
      ${itemFilterText}
    ) {
      cursor
      ${itemsQueryPart}
    }
  }
  me {
    id
    name
    email
    phone
    photo_original
    photo_small
    utc_hours_diff
    account {
      id
      name
      slug
    }
  }
}
`, { apiVersion: '2023-10' });

        var board = boardData.data.boards.find(b => b.id === this.mondayContext.boardId.toString());
        if (!board) {
            alert("Board not found");
            throw new Error("Board not found");
        }
        if (board.items_page && board.items_page.items && board.items_page.cursor) {
            var itemsData = board.items_page.items;
            var queryCursor = board.items_page.cursor;
            while (queryCursor) {
                //console.log("Query cursor: " + queryCursor);
                // TODO: Check complexity limit first -> Slow down?
                // Complexity with limit:100 12206 (without assets: 166)

                if (itemsData.length >= 500)
                    itemsQueryPart = this.getItemsQueryPart(false);

                var pageData = await monday.api(`
query {
  complexity {
    query
    after
  }
  next_items_page (limit: ${itemsLimit}, cursor: "${queryCursor}") {
    cursor
    ${itemsQueryPart}
  }
}
`, { apiVersion: '2023-10' });
                console.log(pageData);  // TODO: Remove
                queryCursor = undefined;
                if (pageData && pageData.data && pageData.data.next_items_page && (pageData.data.next_items_page.items.length > 0)) {
                    itemsData = itemsData.concat(pageData.data.next_items_page.items);
                    queryCursor = pageData.data.next_items_page.cursor;
                }
            }
            board.items_page.items = itemsData;
        }

        return boardData;
    }

    getColumnsForTable() {
        var columns = this.props.columns;
        if (this.props.isItemPrint)
            columns = this.props.subItemsColumns;
        if (!columns)
            return [];
        return columns.filter(c => c.type !== 'subtasks' && c.type !== 'button');
    }

    isBlueprintTableColumnChecked(column) {
        var id = column.id;
        return this.state.blueprintTableSelectedColumns.includes(id);
    }

    renderDocumentContent() {
        //console.log(this.state);

        const fullThumbnailUrl = '/document/Thumbnail?Id=' + this.state.documentContent.id +
            '&SessionToken=' + window.mondaySessionToken;
        const isSmallScreen = window.innerWidth < 700;
        if (isSmallScreen) {
            return (<div>
                <h2>Your document was generated successfully</h2>
                <img className="app-spirit-doc-preview-img" src={fullThumbnailUrl} alt="Preview" onClick={this.previewCreatedDocument}></img>
                <div>
                    <span>{this.state.documentContent.name}</span><br />
                    <br />
                    <Button className="app-spirit-downloadbutton" onClick={this.downloadCreatedDocument}>
                        Download document
                    </Button>
                    <Button rightIcon={ExternalPage} className="app-spirit-downloadbutton" kind={Button.kinds.SECONDARY} onClick={this.previewCreatedDocument}>
                        Open preview
                    </Button>
                    <br />
                    <br />
                    <div>You want to customize the document?
                        <Flex>Try our app&nbsp;
                            <Link href="https://monday.com/marketplace/10000360" text="DocExport" />&nbsp;|&nbsp;
                            <Link href="https://www.docexport.com/contact/" text="Contact us" />
                        </Flex>
                    </div>
                </div>
                <br />
                <Button kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                    Close
                </Button>
            </div>);
        }
        return (
            <div>
                <Box
                    className="app-spirit-document-ready-box"
                    border={Box.borders.DEFAULT}
                    shadow={Box.shadows.MEDIUM}
                    rounded={Box.roundeds.MEDIUM}>
                    <Flex className="app-spirit-doc-ready-flex">
                        <img className="app-spirit-doc-preview-img" src={fullThumbnailUrl} alt="Preview" onClick={this.previewCreatedDocument}></img>
                        <div className="app-spirit-doc-ready-text">
                            <h2>Your document was generated successfully</h2>
                            <span>{this.state.documentContent.name}</span><br />
                            <br />
                            <Button className="app-spirit-downloadbutton" onClick={this.downloadCreatedDocument}>
                                Download document
                            </Button>
                            <Button rightIcon={ExternalPage} className="app-spirit-downloadbutton" kind={Button.kinds.SECONDARY} onClick={this.previewCreatedDocument}>
                                Open preview
                            </Button>
                            <br />
                            <br />
                            <div>You want to customize the document?
                                <Flex>Try our app&nbsp;
                                    <Link href="https://auth.monday.com/oauth2/authorize?client_id=7b764383ce302bd4f8cfa265c4052bea&response_type=install" text="DocExport" />&nbsp;|&nbsp;
                                    <Link href="https://www.docexport.com/contact/" text="Contact us" />
                                </Flex>
                            </div>
                        </div>
                    </Flex>
                </Box>
                <br />
                <Button kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                    Close
                </Button>
            </div>
        );
    }

    renderDocumentError() {
        return (
            <div>
                <Box
                    className="app-spirit-document-ready-box"
                    border={Box.borders.DEFAULT}
                    shadow={Box.shadows.MEDIUM}
                    rounded={Box.roundeds.MEDIUM}>
                    <h2>Error generating your document</h2>
                    <span>Error: {this.state.documentError.error}</span><br />
                    <br />
                    <Flex>Need help?&nbsp;
                        <Link href="https://www.docexport.com/help/template/" text="Help Center" />&nbsp;|&nbsp;
                        <Link href="https://www.docexport.com/contact/" text="Contact us" />
                    </Flex>
                    <br />
                </Box>
                <br />
                <Button kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                    Close
                </Button>
            </div>
        );
    }

    hasColumnsToSelect() {
        if (this.props.isItemPrint)
            if (!this.props.subItemsColumns)
                return false;
        return true;
    }

    cannotGenerate() {
        if (this.state.blueprintTableSelectedColumns.length === 0)
            return true;
        if (this.state.blueprintTableSelectedColumns.length > 10)
            return true;
        return false;
    }

    render() {
        var content = this.internalRender();
        return (<div className="app-spirit-boardprint-frame">{content}</div>)
    }

    internalRender() {
        if (this.mondayContext.user.isViewOnly) {
            return (
                <p>As a viewer, you are unable to use this app.</p>
            );
        }

        if (this.state.loading)
            return (<p><em>Loading...</em></p>);

        if (this.state.documentError)
            return this.renderDocumentError();
        if (this.state.documentContent)
            return this.renderDocumentContent();

        return (
            <Flex align={Flex.align.START} gap={32}>
                <div className="app-spirit-two-column">

                    {this.hasColumnsToSelect() && <div>
                        <p>{this.texts.selectColumnsText}</p>
                        {this.getColumnsForTable().map(c =>
                            <Checkbox key={c.id} onChange={(e) => this.handleTableColumnCheckboxChange(c)} checked={this.isBlueprintTableColumnChecked(c)}
                                label={c.item_terminology ?? c.title}
                            />
                        )}
                    </div>}
                    <br />
                    <Button disabled={this.cannotGenerate()} loading={this.state.executing} onClick={this.handleClickGenerate}>
                        Generate document
                    </Button>
                </div>
                <div className="app-spirit-two-column">
                    <Box backgroundColor={Box.backgroundColors.GREY_BACKGROUND_COLOR} border={Box.borders.DEFAULT} padding={Box.paddings.LARGE} rounded={Box.roundeds.MEDIUM}>
                        <p className="app-spirit-explanation-text-top">Follow these steps to create a PDF document from your board:</p>                        
                        <ol>
                            <li>
                                Select the columns you want to include in your table. For optimal results, please choose a maximum of 10 columns.</li>
                            <li>
                                Click on "Generate document" and wait while the PDF document is generated.
                            </li>
                            <li>Download the generated PDF document.</li>
                        </ol>
                        <p className="app-spirit-explanation-text-bottom"><Link href="https://www.docexport.com/board-print-pdf-export/" text="How to use Board Print" /></p>                        
                    </Box>
                </div>
            </Flex>
        );
    }
}
